import { useRouter } from "next/router"
import Layout from "components/core/Layout"
import SEO from "components/core/SEO"
import { HeroSection } from "components/home/HeroSection"
import EmployeesSection from "components/home/EmployeesSection"
import { SecuritySection } from "components/home/SecuritySection"
import CfoSuiteSection from "components/home/CfoSuiteSection"
import GeneralCounselsSection from "components/home/GeneralCounselsSection"
import TradingEnforcementTrendsSection from "components/home/TradingEnforcementTrendsSection"
import TestimonialSection from "components/home/TestimonialSection"
import DemoSection from "components/home/DemoSection"
import EmployeeBenefitsSection from "components/home/EmployeeBenefitsSection"

const Landing = () => {
  const router = useRouter()

  const openContactModal = () => {
    router.push("/get-a-demo")
  }
  return (
    <Layout theme="black">
      <SEO
        title="The Operating System for Public Company Equity"
        description="One platform for employees, HR, Finance, and Legal. Integrates with your existing stock plan administrator."
      />

      <HeroSection openContactModal={openContactModal} />
      <EmployeesSection />
      <CfoSuiteSection openContactModal={openContactModal} />
      <GeneralCounselsSection openContactModal={openContactModal} />
      <TradingEnforcementTrendsSection />
      <EmployeeBenefitsSection />
      <TestimonialSection />
      <SecuritySection />
      <DemoSection />
    </Layout>
  )
}

export default Landing
